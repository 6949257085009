import React from "react"
import MainFooter from "../../../footer/main_footer"
import MainHeader from "../../../main_header/main_header"
import * as classes from "./program_standard_based_training.module.scss"
import loadable from "@loadable/component"
const CoursesList = loadable(
  () => import("../../../courses_list/courses_list"),
  {
    fallback: <div></div>,
  }
)
const ProgramBanner = loadable(() => import("./banner"), {
  fallback: <div></div>,
})

export default function ProgramStandard() {
  return (
    <React.Fragment>
      <MainHeader />
      <ProgramBanner />
      <div className={classes.text_div}>
        <h2>Program and Standard Based Training</h2>
        <p>
          Often utilized by most of our customers, our Program and Standard
          Based Training service has been essential to success on many
          occasions. When it comes to not one but all of our services, you can
          count on Master Trainers to cater to your every need. We pride
          ourselves on our exceptional program-based training service and
          guarantee that you’ll enjoy every minute of working with our capable
          and experienced team. Let us know how we can assist you today.
        </p>
      </div>
      <CoursesList />
      <MainFooter />
    </React.Fragment>
  )
}
